import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PostCard from "../components/postCard"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const BlogIndex = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title;
  const posts = sortBlogPosts(data.allMarkdownRemark.edges);  
  const instaNodes = sortInstaNodes(data.allInstaNode.nodes);

  function sortBlogPosts (posts) {
    return posts.sort((post1, post2) => {
      return new Date(post2.node.frontmatter.date) - new Date(post1.node.frontmatter.date);
    });
  }

  function sortInstaNodes(instaNodes) {
    return instaNodes.sort((instaNode1, instaNode2) => {
      return instaNode2.timestamp - instaNode1.timestamp;
    });
  }

  return (
    <Layout title={siteTitle}>
      <SEO
        title="All posts"
        keywords={[`blog`, `vegetarian`, `vegan`, `recipes`, `food`, `no`, `onions`, `garlic`, `jain`, `hindu`, `fodmap`, `gatsby`, `javascript`, `react`]}
      />
      {data.site.siteMetadata.description && (
        <header className="page-head">
          <h2 className="page-head-title">
            {data.site.siteMetadata.description}
          </h2>
        </header>
      )}
      <div className="post-feed">
        {instaNodes.map((node, index) => {
          return (
            <PostCard
              key={node.id}
              count={index}
              node={{
                post: posts[index].node,
                ...node,
              }}
              postClass={`post`}
            />
          )
        })}
      </div>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            # thumbnail {
            #   childImageSharp {
            #     fluid(maxWidth: 1360) {
            #       ...GatsbyImageSharpFluid
            #     }
            #   }
            # }
          }
        }
      }
    }
    allInstaNode {
      nodes {
        id
        caption
        username
        timestamp
        localFile {
          childImageSharp {
            fluid(maxWidth: 1360) {
              # srcSet
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <BlogIndex location={props.location} props data={data} {...props} />
    )}
  />
)
